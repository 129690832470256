export function required (value) {
    return !!value || 'Required Field.';
}

export function validEmail (value) {
    if (value != null && value != undefined && value != '') {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Please type a valid email!';
    }
    else {
        return true;
    }
}

export function validPassword (value) {
    const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return pattern.test(value) || 'Password must be at least 8 characters, 1 uppercase and 1 lowercase letter';
}

export function requiredFile (value) {
    if (value == null || value.length <= 0) {
        return 'Required Field.'
    }
    else {
        return true;
    }
}