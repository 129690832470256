<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogMessage"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					{{ headerTitle }}
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
					<strong>{{ bodyText }}</strong>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">

						<v-btn
							class="mx-2"
							outlined
							@click="OK()"
						>
							OK
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    export default {

        props: {

			showDialogMessage: {
				default: false
			},

			headerTitle: {
				type: String,
				default: ""
			},

			bodyText: {
				type: String,
				default: ""
			},
		},

        methods: {

            OK() {
                this.$emit('methodOKToCall');
                this.$emit('update:showDialogMessage', false)
            }
        }
    };
</script>
