<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Profile</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'profileForm', params: {id: 0} }"
                        >
                            New Profile
                        </v-btn>
                    </template>
                    <span>New Profile</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                            small
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'profileForm'"
                            :showButtons="{
                                edit: true,
                                delete: false,
                                cancel: item.status === 1 ? true : false,
                                active: item.status === 0 ? true : false
                            }"
                            @confirmCancel="confirmCancel"
                            @confirmActive="confirmActive" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            statusId: 0,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Name", value: "name", sortable: true },
                { text: "System Admin", value: "systemAdminDescription", sortable: true },
                { text: "System User", value: "userSystemDescription", sortable: true },
                { text: "Validate Password Change", value: "validatePasswordChangeDescription", sortable: true },
                { text: "Budget Control", value: "budgetControlDescription", sortable: true },
                { text: "Default Page", value: "menuDefaultSelected.friendlyName", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listProfile: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listProfile.filter((profileFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var name = profileFilter.name.toLowerCase().match(filter)
                    var systemAdminDescription = profileFilter.systemAdminDescription.toLowerCase().match(filter)
                    var userSystemDescription = profileFilter.userSystemDescription.toLowerCase().match(filter)
                    var validatePasswordChangeDescription = profileFilter.validatePasswordChangeDescription.toLowerCase().match(filter)
                    var budgetControlDescription = profileFilter.budgetControlDescription.toLowerCase().match(filter)
                    var defaultPageDescription = profileFilter.menuDefaultSelected.friendlyName != null && profileFilter.menuDefaultSelected.friendlyName != undefined ? 
                                                profileFilter.menuDefaultSelected.friendlyName.toLowerCase().match(filter) : ""
                    var statusDescription = profileFilter.statusDescription.toLowerCase().match(filter)
                    var id = profileFilter.id.toString().match(filter);

                    if(name != null) { return name; } 
                    else if(systemAdminDescription != null) { return systemAdminDescription; } 
                    else if(userSystemDescription != null) { return userSystemDescription; } 
                    else if(validatePasswordChangeDescription != null) { return validatePasswordChangeDescription; } 
                    else if(budgetControlDescription != null) { return budgetControlDescription; } 
                    else if(defaultPageDescription != null) { return defaultPageDescription; } 
                    else if(statusDescription != null) { return statusDescription; } 
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1:
                        color = 'var(--color__main)';
                        break;

                    case 0:
                        color = 'var(--color__cinza_escuro)';
                        break;
                
                    default:
                        break;
                }

                return color
            },
            
            async getRegisters() {

                this.listProfile = await this.$store.dispatch("profileModule/List");
            },

            confirmCancel(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will INACTIVATE this profile, confirm your decision?',
                    methodConfirm: this.cancel,
                    params: id
                };
            },

            async cancel(id) {

                let statusId = this.statusId;

                this.showLoading();

                let result = await this.$store.dispatch("profileModule/UpdateStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmActive(id) {

                this.statusId = 1;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will ACTIVATE this profile, confirm your decision?',
                    methodConfirm: this.active,
                    params: id
                };

            },

            async active(id) {

                this.showLoading();
                let statusId = this.statusId;

                let result = await this.$store.dispatch("profileModule/UpdateStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            }

        },

        created() {
            this.getRegisters();
        }
    })
</script>
